import React from "react";

type Props = {
  isLoading: boolean;
  children?: React.ReactNode;
};

export const Loading: React.FC<Props> = ({ isLoading, children }) => {
  if (isLoading) {
    return (
      <div className="border-4 border-dashed border-gray-200 rounded-lg h-96 p-4">
        <p className="text-lg text-gray-400">Carregando...</p>
      </div>
    );
  }

  return <>{children}</>;
};

export default Loading;
