import React from "react";
import { Header } from "./Header";

type LayoutProps = {
  children?: React.ReactNode;
  pageName?: string;
};

export const Layout: React.FC<LayoutProps> = ({ children, pageName }) => {
  return (
    <div>
      <Header />

      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900">{pageName}</h1>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="px-4 py-6 sm:px-0">{children}</div>
        </div>
      </main>
    </div>
  );
};
