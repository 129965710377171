import React from "react";
import { NavLink } from "react-router-dom";
import { User } from "../../services/SalesmanService";

type SalesmanListItemProps = {
  salesman: User;
};

const SalesmanListItem: React.FC<SalesmanListItemProps> = ({ salesman }) => {
  const makeSalesmanSimulatorLink = ({ username }: User): string => {
    const SIMULATOR_URL = process.env.REACT_APP_SIMULATOR_URL;
    return `${SIMULATOR_URL}/salesman/${username}`;
  };

  const makeSalesmanAvatarByName = ({ name }: User) => {
    return `https://ui-avatars.com/api/?name=${name?.replace(" ", "+")}`;
  };

  return (
    <tr>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10">
            <img
              className="h-10 w-10 rounded-full"
              src={makeSalesmanAvatarByName(salesman)}
              alt={salesman.name}
              title={salesman.name}
            />
          </div>
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">
              {salesman.name}
            </div>

            <div className="text-sm text-gray-500">
              <a
                href={makeSalesmanSimulatorLink(salesman)}
                className="text-gray-500 hover:text-green-900"
                target="_blank"
                rel="noreferrer"
              >
                ver página
              </a>
            </div>
          </div>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-500">{salesman.email}</div>
      </td>

      <td className="px-6 py-4 whitespace-nowrap">
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
          Ativo
        </span>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        <NavLink
          to={`/salesman/${salesman.id}`}
          className="text-green-600 hover:text-green-900"
        >
          Editar
        </NavLink>
      </td>
    </tr>
  );
};

export default SalesmanListItem;
