import React from "react";

import { Layout } from "../components/layout/";

export const Dashboard: React.FC = () => {
  return (
    <Layout pageName="Dashboard">
      <div className="border-4 border-dashed border-gray-200 rounded-lg h-96 p-4">
        <p className="text-lg text-gray-400">Sem informações no momento</p>
      </div>
    </Layout>
  );
};
