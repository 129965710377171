import api from "./api";

export type Syndicate = {
  name: string | undefined;
};

export type Plan = {
  id: string | undefined;
  name: string | undefined;
  coverage: string | undefined;
  co_participation: string | undefined;
  accommodation: string | undefined;
  operator: string | undefined;
  price: number | undefined;
  min_age: number | undefined;
  max_age: number | undefined;
  syndicates: Syndicate[];
};

export const getAll = async (token: string): Promise<Plan[]> => {
  api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const { data } = await api.get<Plan[]>("/plans/all");
  return data;
};
