import api from "./api";

export type User = {
  id: string | undefined;
  name: string | undefined;
  email: string | undefined;
  username: string | undefined;
  created_at: Date;
};

export const getAll = async (token: string): Promise<User[]> => {
  api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const { data } = await api.get<User[]>("/users/salesmans");
  return data;
};

export const getById = async (token: string, id: string): Promise<User> => {
  api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const { data } = await api.get<User>(`/users/${id}`);
  return data;
};
