import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Layout } from "../components/layout/";
import Loading from "../components/Loading";
import { Deal, getById } from "../services/DealsService";
import { useAuth } from "../hooks/auth";

type RouteParams = {
  id: string;
};

export const DealDetail: React.FC = () => {
  const { id } = useParams<RouteParams>();
  const { token } = useAuth();

  const [deal, setDeal] = useState<Deal>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      const deal = await getById(token, id);
      setDeal(deal);
      setIsLoading(false);
    }

    fetchData();
  }, [id, token]);

  return (
    <Layout pageName="Detalhes da Simulação">
      <Loading isLoading={isLoading}>
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Informações
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Pessoais e profissionais.
            </p>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Nome</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {deal?.name}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Telefone</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {deal?.phone}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">E-mail</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {deal?.email}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Profissão</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {deal?.profession.name}
                </dd>
              </div>

              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Sindicato</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {deal?.syndicate.name}
                </dd>
              </div>

              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Plano</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <p>{deal?.plan.name}</p>
                  <p className="text-xs">
                    <span className="text-gray-700">Cobertura:</span>{" "}
                    {deal?.plan.coverage}
                  </p>
                  <p className="text-xs">
                    <span className="text-gray-700">Co-participação:</span>{" "}
                    {deal?.plan.co_participation}
                  </p>
                  <p className="text-xs">
                    <span className="text-gray-700">Acomodação:</span>{" "}
                    {deal?.plan.accommodation}
                  </p>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </Loading>
    </Layout>
  );
};
