import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";

import { Layout } from "../../components/layout/";
import Loading from "../../components/Loading";
import { User, getById } from "../../services/SalesmanService";
import { useAuth } from "../../hooks/auth";

type RouteParams = {
  id: string;
};

export const SalesmanDetail: React.FC = () => {
  const { id } = useParams<RouteParams>();
  const { token } = useAuth();

  const [salesman, setSalesman] = useState<User>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      const deal = await getById(token, id);
      setSalesman(deal);
      setIsLoading(false);
    }

    fetchData();
  }, [id, token]);

  const makeSalesmanSimulatorLink = ({ username }: User): string => {
    const SIMULATOR_URL = process.env.REACT_APP_SIMULATOR_URL;
    return `${SIMULATOR_URL}/salesman/${username}`;
  };

  return (
    <Layout pageName="Editar Vendedor">
      <Loading isLoading={isLoading}>
        <div>
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Perfil
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  Informações do perfil do vendedor.
                </p>
              </div>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <form action="#" method="POST">
                <div className="shadow sm:rounded-md sm:overflow-hidden">
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <div className="grid grid-cols-3 gap-6">
                      <div className="col-span-3 sm:col-span-2">
                        <label
                          htmlFor="company-website"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Pagína personalizada
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                            Link
                          </span>
                          <input
                            type="text"
                            name="company-website"
                            id="company-website"
                            className="focus:ring-green-500 focus:border-green-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                            defaultValue={
                              salesman && makeSalesmanSimulatorLink(salesman)
                            }
                            disabled={true}
                          />
                        </div>
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Nome
                        </label>
                        <input
                          type="text"
                          name="first-name"
                          id="first-name"
                          autoComplete="given-name"
                          defaultValue={salesman?.name}
                          className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="last-name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          E-mail
                        </label>
                        <input
                          type="text"
                          name="last-name"
                          id="last-name"
                          autoComplete="family-name"
                          defaultValue={salesman?.email}
                          className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <NavLink
                      to="/salesmans"
                      className="text-gray-600 hover:text-green-900 mr-4 text-sm"
                    >
                      Cancelar
                    </NavLink>
                    <button
                      type="submit"
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    >
                      Salvar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Loading>
    </Layout>
  );
};
