import React, { useMemo } from "react";
import { formatDistance } from "date-fns";
import { ptBR } from "date-fns/locale";
import { Deal } from "../../services/DealsService";
import { NavLink } from "react-router-dom";

interface DealListItemProps {
  deal: Deal;
}

export const DealListItem: React.FC<DealListItemProps> = ({ deal }) => {
  const { name, created_at, email } = deal;

  const formatDateToTimeAgo = useMemo(
    () =>
      formatDistance(new Date(created_at), new Date(), {
        addSuffix: true,
        locale: ptBR,
      }),
    [created_at]
  );

  // const isDealSentToday = useMemo(
  //   () => differenceInDays(new Date(created_at), new Date()) === 0,
  //   [created_at]
  // );

  return (
    <tr>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10">
            <img
              className="h-10 w-10 rounded-full"
              src={`https://ui-avatars.com/api/?name=${name}`}
              alt=""
            />
          </div>
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">{name}</div>
            <div className="text-sm text-gray-500">{email}</div>
          </div>
        </div>
      </td>
      {/* <td className="px-6 py-4 whitespace-nowrap">
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
          {isDealSentToday ? "Nova" : ""}
        </span>
      </td> */}
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-900">{deal.syndicate.name}</div>
        <div className="text-sm text-gray-500">{deal.profession.name}</div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-900">{deal.plan.name}</div>
        <div className="text-sm text-gray-500">{deal.plan.coverage}</div>
      </td>

      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {formatDateToTimeAgo}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        <NavLink
          to={`/deals/${deal.id}`}
          className="text-indigo-600 hover:text-indigo-900"
        >
          Ver simulação
        </NavLink>
      </td>
    </tr>
  );
};
