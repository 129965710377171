import React, { useEffect, useState } from "react";
import { Layout } from "../components/layout/";
import Loading from "../components/Loading";
import { getAll, User } from "../services/SalesmanService";
import SalesmanList from "../components/SalesmanList/SalesmanList";
import { useAuth } from "../hooks/auth";

export const Salesmans: React.FC = () => {
  const [salesmans, setSalesmans] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { token } = useAuth();

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      const salesmans = await getAll(token);
      setSalesmans(salesmans);
      setIsLoading(false);
    }

    fetchData();
  }, [token]);

  return (
    <Layout pageName="Vendedores">
      <Loading isLoading={isLoading}>
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <SalesmanList salesmans={salesmans} />
              </div>
            </div>
          </div>
        </div>
      </Loading>
    </Layout>
  );
};
