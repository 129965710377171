import React from "react";
import { Switch } from "react-router-dom";

import Route from "./Route";

import {
  Login,
  Dashboard,
  Deals,
  DealDetail,
  Salesmans,
  Profile,
  SalesmanDetail,
  Plans,
} from "../pages";

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Login} />
    <Route path="/profile" component={Profile} isPrivate />
    <Route path="/dashboard" component={Dashboard} isPrivate />
    <Route path="/deals/:id" component={DealDetail} isPrivate />
    <Route path="/deals" component={Deals} isPrivate />
    <Route path="/salesmans" component={Salesmans} isPrivate />
    <Route path="/salesman/:id" component={SalesmanDetail} isPrivate />
    <Route path="/profile" component={Profile} isPrivate />
    <Route path="/plans" component={Plans} isPrivate />
  </Switch>
);

export default Routes;
