import { Fragment, useMemo } from "react";
import { NavLink } from "react-router-dom";

import { Disclosure, Menu, Transition } from "@headlessui/react";
import { useAuth } from "../../hooks/auth";

const navigation = [
  { name: "Dashboard", href: "/dashboard", current: true },
  { name: "Simulações", href: "/deals", current: false },
  { name: "Planos", href: "/plans", current: false },
  { name: "Vendedores", href: "/salesmans", current: false },
];

export const Header: React.FC = () => {
  const { user, signOut } = useAuth();

  const makeAvatarImageSlug = useMemo(() => {
    const slug = user.name.replace(" ", "+");
    return `https://ui-avatars.com/api/?name=${slug}`;
  }, [user]);

  return (
    <>
      <Disclosure as="nav" className="bg-gray-800">
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-16">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <img
                    className="h-8 w-8"
                    src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
                    alt="Workflow"
                  />
                </div>
                <div className="block">
                  <div className="ml-10 flex items-baseline space-x-4">
                    {navigation.map((item) => (
                      <NavLink
                        key={item.name}
                        to={item.href}
                        className={
                          "px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                        }
                        activeClassName={"bg-gray-900 text-white"}
                      >
                        {item.name}
                      </NavLink>
                    ))}
                  </div>
                </div>
              </div>

              <div className="hidden md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  <Menu as="div" className="ml-3 relative">
                    <div>
                      <Menu.Button className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                        <span className="sr-only">Menu do usuário</span>
                        <div className="flex flex-col">
                          <span className="px-3 text-sm font-medium text-gray-300 text-right">
                            {user.name}
                          </span>

                          <span className="px-3 text-xs font-medium text-gray-300 opacity-70 text-right">
                            {user.email}
                          </span>
                        </div>
                        <img
                          className="h-10 w-10 rounded-full"
                          src={makeAvatarImageSlug}
                          alt={user.name}
                          title={user.name}
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          <NavLink
                            to={""}
                            className={"block px-4 py-2 text-sm text-gray-700"}
                            onClick={() => signOut()}
                          >
                            Sair
                          </NavLink>
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
        </>
      </Disclosure>
    </>
  );
};
