import React from "react";
import { Plan } from "../../services/PlansService";

interface PlanListItemProps {
  plan: Plan;
}

export const PlanListItem: React.FC<PlanListItemProps> = ({ plan }) => {
  const { name, coverage, operator, min_age, max_age, price, syndicates } =
    plan;

  return (
    <tr>
      <td className="px-6 py-3 whitespace-nowrap">
        <div className="text-xs text-gray-500">{syndicates[0].name}</div>
      </td>
      <td className="px-6 py-3 whitespace-nowrap">
        <div className="text-xs text-gray-900">{name}</div>
      </td>

      <td className="px-6 py-3 whitespace-nowrap">
        <div className="text-xs text-gray-500">{coverage}</div>
      </td>

      <td className="px-6 py-3 whitespace-nowrap">
        <div className="text-xs text-gray-500">{operator}</div>
      </td>

      <td className="px-6 py-3 whitespace-nowrap">
        <div className="text-xs text-gray-500 text-center">{`${min_age} a ${max_age}`}</div>
      </td>

      <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-500">
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
          {`R$ ${price}`}
        </span>
      </td>
    </tr>
  );
};
