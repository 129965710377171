import React from "react";
import { User } from "../../services/SalesmanService";
import SalesmanListItem from "../SalesmanListItem/SalesmanListItem";

type SalesmanListProps = {
  salesmans: User[];
};

const SalesmanList: React.FC<SalesmanListProps> = ({ salesmans }) => {
  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Nome
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            E-mail
          </th>

          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Status
          </th>
          <th scope="col" className="relative px-6 py-3">
            <span className="sr-only">Ver Página</span>
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {salesmans.map((salesman) => (
          <SalesmanListItem key={salesman.id} salesman={salesman} />
        ))}
      </tbody>
    </table>
  );
};

export default SalesmanList;
