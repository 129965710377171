import api from "./api";

export type Deal = {
  id: string | undefined;
  name: string | undefined;
  phone: string | undefined;
  email: string | undefined;
  created_at: Date;
  profession: Profession;
  syndicate: Syndicate;
  plan: Plan;
};

export type Profession = {
  name: string | undefined;
};

export type Syndicate = {
  name: string | undefined;
};

export type Plan = {
  name: string | undefined;
  coverage: string | undefined;
  co_participation: string | undefined;
  accommodation: string | undefined;
  operator: string | undefined;
};

export const getAll = async (token: string): Promise<Deal[]> => {
  api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const { data } = await api.get<Deal[]>("/deals");
  return data;
};

export const getById = async (token: string, id: string): Promise<Deal> => {
  api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const { data } = await api.get<Deal>(`/deals/${id}`);
  return data;
};
